<template>
  <transition name="modal">
    <Reconnect v-if="!store.state.auth.isConnected" />
  </transition>
  <transition name="modal">
    <RequestStatus :pending="store.state.request.pending" :error="store.state.request.error" />
  </transition>
  <nav class="nav" v-if="store.state.auth.isAuthenticated">
    <ul>
      <li ref="logo"><a class="nav-logo" href="https://tridecagram.ru" :style="{textDecoration: 'none'}">
        <img src="./global_assets/img/nav-logo.svg">
        <span v-if="logoTextShow" class="logo__text">Tridecagram</span>
        </a></li>
      <ul ref="navBar" :class="navClass" :style="navStyle">
        <li><router-link to="/orders">Заказы</router-link></li>
        <li><router-link to="/places">Производства</router-link></li>
        <li><router-link to="/organizations">Организации</router-link></li>
        <li><router-link to="/actions">Действия</router-link></li>
        <li><router-link to="/materials">Материалы</router-link></li>
        <li><router-link to="/machines">Станки</router-link></li>
        <li><router-link to="/labels">Примечания</router-link></li>
        <li><router-link to="/approximate-prices">Расчет примерных цен</router-link></li>
        <li><router-link to="/statistics">Статистика цен</router-link></li>
        <li><router-link to="/clients-info">Клиенты</router-link></li>
        <li :class="{'last-visible' : !isAdmin}"><router-link to="/options">Настройки</router-link></li>
        <template v-if="isAdmin">
          <li><router-link to="/logs">Логи</router-link></li>
          <li><router-link to="/crawler">Crawler</router-link></li>
          <li><router-link to="/maps-scrapper">Maps scrapper</router-link></li>
          <li><router-link to="/website">Вебсайт</router-link></li>
          <li><router-link to="/api-test">Тесты</router-link></li>
          <li :class="{'last-visible' : isAdmin}"><router-link to="/departments">Отделения</router-link></li>
        </template>
      </ul>
      <ul ref="rightCorner">
        <div ref="themeSwitcher" @click="switchTheme" class="theme-switcher">
          <div class="theme-switcher__img">
            <svg class="theme-switcher__img sun-icon" :style="sunIconStyle"></svg>
            <svg class="theme-switcher__img moon-icon"  :style="moonIconStyle"></svg>
          </div>
          <div class="theme-switcher__text">{{themeSwitchText}}</div>
        </div>
        <div ref="menuBurger" @click="onMenuBurgerClick" class="header__burger">
          <span></span>
        </div>
      </ul>
    </ul>
  </nav>
  <!-- <CookiesAcceptanceForm/> -->
  <HeadingForm :with-line="false" :type="'h2'" :header-text="currentRouteName" />
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, reactive, watch } from 'vue';
import Reconnect from '@/rpc_client/service_components/Reconnect.vue';
import RequestStatus from '@/rpc_client/service_components/RequestStatus.vue';
import HeadingForm from './rpc_client/service_components/HeadingForm.vue';
import { useStore } from '@/rpc_client/useStore';
import '@/global_assets/global.js';
import { useRouter } from 'vue-router';
import CookiesAcceptanceForm from './rpc_client/service_components/CookiesAcceptanceForm.vue';
import { THEME_COOKIE_ATTRIBUTE } from './rpc_client/misc';

const router = useRouter()

const store = useStore();

const body = document.body;
const html = document.documentElement;
const isAdmin = computed(() => store.state.auth.userRole === 'Admin');

const navBar = ref<HTMLElement | null>(null);
const navClass = reactive({ 'ul-nav': true, sidebar: false })
const navStyle = reactive({ display: '' })
const rightCorner = ref<HTMLElement | null>(null);
const menuBurger = ref<HTMLElement | null>(null);
let sideBarOpen = menuBurger.value?.classList.contains('open') ?? false;
function onMenuBurgerClick () {
  if (menuBurger.value === null || navBar.value === null || rightCorner.value === null) return;
  sideBarOpen = !sideBarOpen;

  navClass.sidebar = sideBarOpen;
  navStyle.display = sideBarOpen ? 'flex' : 'none';
  if (sideBarOpen) {
    body.classList.add('no-scroll');
    menuBurger.value.classList.add('open');
    navBar.value.prepend(menuBurger.value);
  } else {
    body.classList.remove('no-scroll');
    menuBurger.value.classList.remove('open');
    rightCorner.value.append(menuBurger.value);
  }
}

const moonIconStyle = reactive({ display: 'block' });
const sunIconStyle = reactive({ display: 'none' });
let darkTheme = body.classList.contains('dark-theme');
const themeSwitchText = ref('Светлая тема');
function switchTheme () {
  darkTheme = body.classList.toggle('dark-theme');

  themeSwitchText.value = darkTheme ? 'Светлая тема' : 'Темная тема';
  if (darkTheme) {
    moonIconStyle.display = 'none';
    sunIconStyle.display = 'block';
    document.cookie = ('theme=dark;' + THEME_COOKIE_ATTRIBUTE);
    html.classList.add('html-scrollbar-dark')
    html.classList.remove('html-scrollbar-light')
  } else {
    moonIconStyle.display = 'block';
    sunIconStyle.display = 'none';
    document.cookie = ('theme=light;' + THEME_COOKIE_ATTRIBUTE);
    html.classList.remove('html-scrollbar-dark')
    html.classList.add('html-scrollbar-light')
  }
}

function setThemeOnInitialization () {
  let foundThemeCookie = false;
  let cookies = document.cookie.split(';').map(el => el.trim());
  for (let cookie of cookies) {
    let pair = cookie.split('=');
    if (pair[0] === 'theme') {
      if (pair[1] === 'light') { darkTheme = false; }
      foundThemeCookie = true;
      break
    }
  }
  if (!foundThemeCookie) {
    document.cookie = ('theme=dark; ' + THEME_COOKIE_ATTRIBUTE);
  } else {
    if (!darkTheme) { switchTheme(); }
  }
}
onMounted(setThemeOnInitialization);

const routerMap = new Map([
  ['Orders', 'Заказы'],
  ['Places', 'Производства'],
  ['Organizations', 'Организации'],
  ['Actions', 'Действия'],
  ['Materials', 'Материалы'],
  ['Machines', 'Станки'],
  ['Labels', 'Примечания'],
  ['ApproximatePrices', 'Расчет примерных цен'],
  ['Statistics', 'Статистика цен'],
  ['ClientsInfo', 'Клиенты'],
  ['Options', 'Настройки'],
  ['Logs', 'Логи'],
  ['Crawler', 'Crawler'],
  ['MapsScrapper', 'Maps scrapper'],
  ['ApiTests', 'Тесты'],
  ['Website', 'Вебсайт']
]);
const currentRouteName = computed(() => {
  return routerMap.get(router.currentRoute.value.name?.toString() ?? '') ?? '';
});

function getChildWidth (element:HTMLElement | Element) {
  let width = element.clientWidth;
  let marginRight = parseFloat(element.computedStyleMap().get('margin-right')?.toString() ?? '');
  let marginLeft = parseFloat(element.computedStyleMap().get('margin-left')?.toString() ?? '');
  let paddingRight = parseFloat(element.computedStyleMap().get('padding-right')?.toString() ?? '');
  let paddingLeft = parseFloat(element.computedStyleMap().get('padding-left')?.toString() ?? '');
  let elementWidht = marginLeft + marginRight + paddingLeft + paddingRight + width;
  return elementWidht; 
}

const elementsIsNull = computed(() => 
  navBar.value === null || menuBurger.value === null || 
  themeSwitcher.value === null || logo.value === null
);
const themeSwitcher = ref<HTMLElement | null>(null);
const headWidth = computed(() => {
  if (elementsIsNull.value) return 0
  let navBarWidth = 0;
  for (let children of navBar.value!.children) {
    if (children === themeSwitcher.value) continue;
    navBarWidth += getChildWidth(children);
  }
  let headRealWidth = navBarWidth + logo.value!.getBoundingClientRect().width + 
    menuBurger.value!.getBoundingClientRect().width + themeSwitcher.value!.getBoundingClientRect().width;

  return headRealWidth; 
})

const logo = ref<HTMLElement | null>(null);
const logoTextShow = ref(false);
const showRouteName = ref(false);
function handleWindowResize () {
  if (elementsIsNull.value) return; 
  let burgerStyle = menuBurger.value!.style;

  if (window.innerWidth > headWidth.value) {
    navStyle.display = 'flex';
    burgerStyle.display = 'none';
    logoTextShow.value = false;
    showRouteName.value = false;
  } else {
    navStyle.display = 'none';
    burgerStyle.display = 'flex';
    logoTextShow.value = true;
    showRouteName.value = true;
  }

  if (window.innerWidth < 650 && burgerStyle.display === 'flex') {
    navBar.value!.append(themeSwitcher.value!);
  } else {
    menuBurger.value!.before(themeSwitcher.value!);
  }
}
watch(elementsIsNull, isNull => { if (!isNull) handleWindowResize(); })
onMounted(() => window.addEventListener('resize', handleWindowResize));
onBeforeUnmount(() => window.removeEventListener('resize', handleWindowResize));

function closeSidebar () {
  if (menuBurger.value!.classList.contains('open') && navClass.sidebar) {
    navStyle.display = 'none'; 
    navClass.sidebar = false; 
    menuBurger.value?.classList.remove('open');
    body.classList.remove('no-scroll');
    rightCorner.value!.append(menuBurger.value!);
    sideBarOpen = false;
  }
}

function eventListener (event: Event) {
  if (
    navBar.value === null || rightCorner.value === null || 
    menuBurger.value === null || event.target === null) return;

  // @ts-expect-error: property exist 
  if (event.target.parentElement === menuBurger.value) return;

  if (event.target !== navBar.value) closeSidebar();
}
onMounted(() => window.addEventListener('mouseup', eventListener));
onBeforeUnmount(() => window.removeEventListener('mouseup', eventListener));

</script>
