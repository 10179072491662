<template>
    <div class="custom-header">
      <template v-if="headerText.length">
        <h1 v-if="h1" class="custom-header__text">{{ headerText }}</h1>
        <h2 v-if="h2" class="custom-header__text">{{ headerText }}</h2>
        <h3 v-if="h3" class="custom-header__text">{{ headerText }}</h3>
      </template>
      <div v-if="withLine" class="custom-header__line"></div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
const props = withDefaults(defineProps<{headerText: string, type:string, withLine?: boolean}>(), { withLine: true });

const h1 = ref(false);
const h2 = ref(false);
const h3 = ref(false);

function selectHeadingType () {
  switch (props.type) {
    case 'h1':
      h1.value = true; 
      break;
    case 'h2':
      h2.value = true; 
      break;
    default :
      h3.value = true; 
      break;
  }
}
onMounted(selectHeadingType);

</script>
