import { createApp } from 'vue'
import App from '@/App.vue'
// import '@/registerServiceWorker'
import '@/unregisterServiceWorker'
import router from '@/router'
import { WebSocketRpc } from '@/rpc_client/WebSocketRpc'
import { key } from '@/rpc_client/useStore'
import { onAuthChanged, RpcClient } from '@/rpc_client/apiAbstract'
import { createStore, State } from '@/generated/store'
import { createGlobalPropsAndProvideApi, protocolVersion } from '@/generated/api'

// 1) Rpc client init
let url = '';
if (process.env.NODE_ENV === 'production') url = 'wss://api.core.tridecagram.ru/';
else url = 'ws://localhost:1313';
const wsp = new WebSocketRpc(url);
const client = new RpcClient(wsp, protocolVersion);

// 2) Store creation with custom and model data
// create custom store data and typings through module augmentation
interface CustomStoreData{
  appName: string
}
declare module '@/generated/store' {
  interface State {
    custom: CustomStoreData
  }
}
const customData: CustomStoreData = { appName: 'core' };

// create custom store mutations
const customMutations = {};

const store = createStore(client, wsp, { custom: customData }, customMutations);

// 3) Main app creating
export const app = createApp(App).use(store, key).use(router);

// 4) Inserting api objects and rpc client as global properties and provide for composition API
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $RpcClient: RpcClient
  }
}
app.config.globalProperties.$RpcClient = client; app.provide('RpcClient', client);
createGlobalPropsAndProvideApi(app, wsp);

// 5) Main app mounting
const vm = app.mount('#app');

// 6) Setup redirecting to login page in case of token absence
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && client.token.length === 0) {
    next({ name: 'Login' })
  } else { 
    next() 
  } 
})

onAuthChanged.addListener(newAuth => { if (newAuth.isAuthenticated && router.currentRoute.value.name === 'Login') { router.push('/orders') } })

// 7) Connect client to server
client.connectToServer();
