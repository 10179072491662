import { AbstractModel_Remote, AbstractObject_Remote, onRequestChanged, modelReloadFunctionsList } from '@/rpc_client/apiAbstract'
import { App } from 'vue'
import { WebSocketRpc } from '@/rpc_client/WebSocketRpc'
import { client_driven_msg, object_update_msg } from '@/rpc_client/messages'
import { object_msg } from './objectMsg'
import Long from 'long'

export const protocolVersion = 1731071525750

export enum ExposedObjectId {
  PlacesApi_Id,
  OrganizationsApi_Id,
  ServiceApi_Id,
  OrdersApi_Id,
  AIApi_Id,
  WebsiteApi_Id,
  ActionTypesModel_Id,
  WebsiteActionsModel_Id,
  ActionMaterialsModel_Id,
  MachineTypesModel_Id,
  MaterialTypesModel_Id,
  LabelTypesModel_Id,
  PlacesModel_Id,
  OrganizationsRuModel_Id,
  OrganizationsRuInfoModel_Id,
  BanksRuModel_Id,
  PlaceActionsModel_Id,
  PlacePhonesModel_Id,
  PlaceEmailsModel_Id,
  PlaceMachinesModel_Id,
  PlaceLabelsModel_Id,
  LaserCuttingPricesModel_Id,
  LocalLaserCuttingPricesModel_Id,
  LaserEngravingPricesModel_Id,
  LocalLaserEngravingPricesModel_Id,
  LogsModel_Id,
  CitiesRuModel_Id,
  CityActionsModel_Id,
  OrdersModel_Id,
  OrderStatusesModel_Id,
  OrderCommentsModel_Id,
  OrderCommentFilesModel_Id,
  UserDeliveryProfilesModel_Id,
  OrderStagesModel_Id,
  OrderStageStatusesModel_Id,
  OrderStageDeliveryProfilesModel_Id,
  OrderStageCommentsModel_Id,
  OrderStageCommentFilesModel_Id,
  UploadedFilesModel_Id,
  CommercialOfferRequestsModel_Id,
  LaserCuttingStagesModel_Id,
  LaserEngravingStagesModel_Id,
  WebCrawlerWebsitesModel_Id,
  WebCrawlerPendingPagesModel_Id,
  WebCrawlerUselessPathsModel_Id,
  WebCrawlerPricePathsModel_Id,
  DepartmentsModel_Id,
  DepartmentActionsModel_Id,
  LaserCuttingMaterialThicknessesModel_Id
}

// API objects
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $PlacesApi: PlacesApi_Remote,
    $OrganizationsApi: OrganizationsApi_Remote,
    $ServiceApi: ServiceApi_Remote,
    $OrdersApi: OrdersApi_Remote,
    $AIApi: AIApi_Remote,
    $WebsiteApi: WebsiteApi_Remote
  }
}

// Allowed roles:
// Manager, Admin
export class PlacesApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.PlacesApi_Id

  // Allowed roles: Admin, Manager
  async updatePlaceByManager (place: Uint8Array, placeActions: Uint8Array, laserCuttingPrices: Uint8Array, laserEngravingPrices: Uint8Array, placePhones: Uint8Array, placeEmails: Uint8Array, placeMachines: Uint8Array, placeLabels: Uint8Array, plcaceId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.updatePlaceByManager,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ update_place_by_manager_args: { place: place, place_actions: placeActions, laser_cutting_prices: laserCuttingPrices, laser_engraving_prices: laserEngravingPrices, place_phones: placePhones, place_emails: placeEmails, place_machines: placeMachines, place_labels: placeLabels, plcace_id: plcaceId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async assignOrgToPlace (placeId: Long, orgId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.assignOrgToPlace,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ assign_org_to_place_args: { place_id: placeId, org_id: orgId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async searchPlace (placeName: string): Promise<object_msg.search_place_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchPlace,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_place_args: { place_name: placeName } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_place_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async searchPlaceByAction (action: string): Promise<object_msg.search_place_by_action_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchPlaceByAction,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_place_by_action_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_place_by_action_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async searchPlaceByActionAndLocation (action: string, deliveryProfileID: Long, radiusM: number, fuzzySearch: boolean, limit: number): Promise<object_msg.search_place_by_action_and_location_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchPlaceByActionAndLocation,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_place_by_action_and_location_args: { action: action, delivery_profile_id: deliveryProfileID, radius_m: radiusM, fuzzy_search: fuzzySearch, limit: limit } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_place_by_action_and_location_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async selectPlace (placeId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.selectPlace,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ select_place_args: { place_id: placeId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getPlaceContactInfo (placeId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getPlaceContactInfo,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_place_contact_info_args: { place_id: placeId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async selectCityAction (action: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.selectCityAction,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ select_city_action_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getCityPlacesByAction (action: string, cityId: Long): Promise<object_msg.get_city_places_by_action_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getCityPlacesByAction,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_city_places_by_action_args: { action: action, city_id: cityId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_city_places_by_action_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async searchCustomPlaceActionTypes (action: string): Promise<object_msg.search_custom_place_action_types_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchCustomPlaceActionTypes,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_custom_place_action_types_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_custom_place_action_types_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin
  async splitPlaceActionTypes (oldValue: string, newValues: Uint8Array): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.splitPlaceActionTypes,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ split_place_action_types_args: { old_value: oldValue, new_values: newValues } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deletePlaceActionTypes (oldValue: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.deletePlaceActionTypes,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ delete_place_action_types_args: { old_value: oldValue } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addActionType (action: string, urlName: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addActionType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_action_type_args: { action: action, url_name: urlName } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async setActionMaterial (action: string, material: string, enabled: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.setActionMaterial,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ set_action_material_args: { action: action, material: material, enabled: enabled } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async reloadActionMaterials (action: string, autoUpdate: boolean, unloadModel: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.reloadActionMaterials,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ reload_action_materials_args: { action: action, auto_update: autoUpdate, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async searchCustomPriceMaterialTypes (): Promise<object_msg.search_custom_price_material_types_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchCustomPriceMaterialTypes,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_custom_price_material_types_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getCustomPriceMaterialUrls (material: string): Promise<object_msg.get_custom_price_material_urls_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getCustomPriceMaterialUrls,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_custom_price_material_urls_args: { material: material } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_custom_price_material_urls_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getCustomActionUrls (action: string): Promise<object_msg.get_custom_action_urls_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getCustomActionUrls,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_custom_action_urls_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_custom_action_urls_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async searchLaserCuttingPriceThicknesses (material: string): Promise<object_msg.search_laser_cutting_price_thicknesses_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchLaserCuttingPriceThicknesses,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_laser_cutting_price_thicknesses_args: { material: material } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_laser_cutting_price_thicknesses_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getLaserCuttingPriceStatistics (material: string, thickness: number, unloadModel: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getLaserCuttingPriceStatistics,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_laser_cutting_price_statistics_args: { material: material, thickness: thickness, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getLocalLaserCuttingPriceStatistics (material: string, thickness: number, unloadModel: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getLocalLaserCuttingPriceStatistics,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_local_laser_cutting_price_statistics_args: { material: material, thickness: thickness, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getLocalLaserCuttingPricesForMaterialByDepartment (material: string, departmentId: Long, unloadModel: boolean): Promise<object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getLocalLaserCuttingPricesForMaterialByDepartment,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_local_laser_cutting_prices_for_material_by_department_args: { material: material, department_id: departmentId, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_local_laser_cutting_prices_for_material_by_department_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin
  async deleteSystemLaserCuttingPrice (material: string, thickness01mm: number, price: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.deleteSystemLaserCuttingPrice,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ delete_system_laser_cutting_price_args: { material: material, thickness01mm: thickness01mm, price: price } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  openWebsitesBySystemLaserCuttingPrice (material: string, thickness01mm: number, price: number): void {
    const encodedMessage = object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ open_websites_by_system_laser_cutting_price_args: { material: material, thickness01mm: thickness01mm, price: price } })).finish();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.openWebsitesBySystemLaserCuttingPrice,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
  }

  // Allowed roles: Admin
  openWebsitesByDepartmentLaserCuttingPrice (material: string, thickness01mm: number, price: number): void {
    const encodedMessage = object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ open_websites_by_department_laser_cutting_price_args: { material: material, thickness01mm: thickness01mm, price: price } })).finish();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.openWebsitesByDepartmentLaserCuttingPrice,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
  }

  // Allowed roles: Admin
  async searchPlacesByDepartmentLaserCuttingPrice (material: string, thickness01mm: number, price: number): Promise<object_msg.search_places_by_department_laser_cutting_price_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchPlacesByDepartmentLaserCuttingPrice,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_places_by_department_laser_cutting_price_args: { material: material, thickness01mm: thickness01mm, price: price } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_places_by_department_laser_cutting_price_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin
  async splitPriceMaterialTypes (oldValue: string, newValues: Uint8Array): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.splitPriceMaterialTypes,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ split_price_material_types_args: { old_value: oldValue, new_values: newValues } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deletePriceMaterialTypes (oldValue: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.deletePriceMaterialTypes,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ delete_price_material_types_args: { old_value: oldValue } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async unifyPriceMaterialType (oldValue: string, newValue: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.unifyPriceMaterialType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ unify_price_material_type_args: { old_value: oldValue, new_value: newValue } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addMaterialType (value: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addMaterialType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_material_type_args: { value: value } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async searchCustomPlaceMachineTypes (): Promise<object_msg.search_custom_place_machine_types_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchCustomPlaceMachineTypes,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_custom_place_machine_types_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async unifyPlaceMachineType (oldValue: string, newValue: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.unifyPlaceMachineType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ unify_place_machine_type_args: { old_value: oldValue, new_value: newValue } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addMachineType (value: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addMachineType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_machine_type_args: { value: value } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async searchCustomPlaceLabelTypes (): Promise<object_msg.search_custom_place_label_types_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchCustomPlaceLabelTypes,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_custom_place_label_types_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async unifyPlaceLabelType (oldKey: string, newKey: string, oldValue: string, newValue: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.unifyPlaceLabelType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ unify_place_label_type_args: { old_key: oldKey, new_key: newKey, old_value: oldValue, new_value: newValue } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addLabelType (key: string, value: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addLabelType,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_label_type_args: { key: key, value: value } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addLabelToPlacesWithAction (action: string, key: string, value: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addLabelToPlacesWithAction,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_label_to_places_with_action_args: { action: action, key: key, value: value } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async stagePlaceInfo (placeId: Long): Promise<object_msg.stage_place_info_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.stagePlaceInfo,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ stage_place_info_args: { place_id: placeId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.stage_place_info_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getStagePlaceAddress (placeId: Long): Promise<string> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.getStagePlaceAddress,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ get_stage_place_address_args: { place_id: placeId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.s!;
  }

  // Allowed roles: Admin
  async addLaserCuttingMaterialThickness (material: string, thickness_01mm: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addLaserCuttingMaterialThickness,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_laser_cutting_material_thickness_args: { material: material, thickness_01mm: thickness_01mm } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async removeLaserCuttingMaterialThickness (material: string, thickness_01mm: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.removeLaserCuttingMaterialThickness,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ remove_laser_cutting_material_thickness_args: { material: material, thickness_01mm: thickness_01mm } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async reloadLaserCuttingMaterialThicknesses (material: string, autoUpdate: boolean, unloadModel: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.reloadLaserCuttingMaterialThicknesses,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ reload_laser_cutting_material_thicknesses_args: { material: material, auto_update: autoUpdate, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async createNewDepartments (minimalCityPopulation: number, placesActionToSearch: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.createNewDepartments,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ create_new_departments_args: { minimal_city_population: minimalCityPopulation, places_action_to_search: placesActionToSearch } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addActionToDepartment (departmentId: Long, action: string, markupPercentage: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addActionToDepartment,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_action_to_department_args: { department_id: departmentId, action: action, markup_percentage: markupPercentage } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addActionToAllDepartments (action: string, markupPercentage: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.addActionToAllDepartments,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ add_action_to_all_departments_args: { action: action, markup_percentage: markupPercentage } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async setupLocalPricesForAction (action: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.setupLocalPricesForAction,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ setup_local_prices_for_action_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  openAddressesInSelenium (addresses: string): void {
    const encodedMessage = object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ open_addresses_in_selenium_args: { addresses: addresses } })).finish();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.openAddressesInSelenium,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
  }

  // Allowed roles: Admin
  async setDepartmentAddress (departmentId: Long, address: string, latitude: number, longitude: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.setDepartmentAddress,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ set_department_address_args: { department_id: departmentId, address: address, latitude: latitude, longitude: longitude } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// Manager, Admin
export class OrganizationsApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.OrganizationsApi_Id

  // Allowed roles: Admin, Manager
  async updateOrganizationByManager (organizations: Uint8Array, orgIdForArchive: Long, userId: Long): Promise<object_msg.update_organization_by_manager_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.updateOrganizationByManager,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ update_organization_by_manager_args: { organizations: organizations, org_id_for_archive: orgIdForArchive, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.update_organization_by_manager_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getOrAddBank (bic: string): Promise<object_msg.get_or_add_bank_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.getOrAddBank,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ get_or_add_bank_args: { bic: bic } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_or_add_bank_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getOrganizationSuggestion (input: string): Promise<object_msg.get_organization_suggestion_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.getOrganizationSuggestion,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ get_organization_suggestion_args: { input: input } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_organization_suggestion_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async searchOrganization (organizationNameInn: string, organizationId: Long): Promise<object_msg.search_organization_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.searchOrganization,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ search_organization_args: { organization_name_inn: organizationNameInn, organization_id: organizationId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_organization_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async selectOrganization (organizationId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.selectOrganization,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ select_organization_args: { organization_id: organizationId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async reloadUserOrganizationsInfo (userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.reloadUserOrganizationsInfo,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ reload_user_organizations_info_args: { user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async archiveUserOrganizaton (organizationId: Long, userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.archiveUserOrganizaton,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ archive_user_organizaton_args: { organization_id: organizationId, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async doesUserHaveOrganization (inn: string, kpp: string, userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.doesUserHaveOrganization,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ does_user_have_organization_args: { inn: inn, kpp: kpp, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// Manager, Admin
export class ServiceApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.ServiceApi_Id

  // Allowed roles: Admin
  getLogs (app: string): void {
    const encodedMessage = object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_logs_args: { app: app } })).finish();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getLogs,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
  }

  // Allowed roles: Admin, Manager
  async getAddressSuggestion (input: string): Promise<object_msg.get_address_suggestion_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getAddressSuggestion,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_address_suggestion_args: { input: input } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_address_suggestion_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin
  async addSiteToCrawlQueue (url: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.addSiteToCrawlQueue,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ add_site_to_crawl_queue_args: { url: url } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getClientsInfo (): Promise<object_msg.get_clients_info_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getClientsInfo,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_clients_info_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async updateUserInfoByManager (userId: Long, userInfo: Uint8Array): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.updateUserInfoByManager,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ update_user_info_by_manager_args: { user_id: userId, user_info: userInfo } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getUserProfileInfo (dullArg: boolean): Promise<object_msg.get_user_profile_info_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getUserProfileInfo,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_user_profile_info_args: { dull_arg: dullArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_user_profile_info_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async isWhatsAppAvailable (phone: string): Promise<object_msg.is_whats_app_available_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.isWhatsAppAvailable,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ is_whats_app_available_args: { phone: phone } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.is_whats_app_available_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin
  voidTest (): void {
    const encodedMessage = new Uint8Array();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.voidTest,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
  }

  // Allowed roles: Admin
  async booleanTest (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.booleanTest,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async dbSelectTest (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.dbSelectTest,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async sendMessageByContactInfo (packedContactInfo: Uint8Array, message: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.sendMessageByContactInfo,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ send_message_by_contact_info_args: { packed_contact_info: packedContactInfo, message: message } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// Manager, Admin
export class OrdersApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.OrdersApi_Id

  // Allowed roles: Admin, Manager
  async updateOrderByManager (orders: Uint8Array, orderComments: Uint8Array, orderCommentFiles: Uint8Array, orderStages: Uint8Array, orderStageComments: Uint8Array, orderStageCommentFiles: Uint8Array, laserCuttingStages: Uint8Array, laserEngravingStages: Uint8Array, orderId: Long, userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateOrderByManager,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_order_by_manager_args: { orders: orders, order_comments: orderComments, order_comment_files: orderCommentFiles, order_stages: orderStages, order_stage_comments: orderStageComments, order_stage_comment_files: orderStageCommentFiles, laser_cutting_stages: laserCuttingStages, laser_engraving_stages: laserEngravingStages, order_id: orderId, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async updateOrderStatusByManger (orderId: Long, status: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateOrderStatusByManger,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_order_status_by_manger_args: { order_id: orderId, status: status } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async approveStage (orderStages: Uint8Array, orderStageComments: Uint8Array, orderStageCommentFiles: Uint8Array, laserCuttingStages: Uint8Array, laserEngravingStages: Uint8Array, userId: Long, orderId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.approveStage,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ approve_stage_args: { order_stages: orderStages, order_stage_comments: orderStageComments, order_stage_comment_files: orderStageCommentFiles, laser_cutting_stages: laserCuttingStages, laser_engraving_stages: laserEngravingStages, user_id: userId, order_id: orderId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async reloadOrderStages (orderId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.reloadOrderStages,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ reload_order_stages_args: { order_id: orderId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async updateDeliveryProfilesByManager (profiles: Uint8Array, userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateDeliveryProfilesByManager,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_delivery_profiles_by_manager_args: { profiles: profiles, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async reloadDeliveryProfiles (autoUpdate: boolean, unload: boolean, userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.reloadDeliveryProfiles,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ reload_delivery_profiles_args: { auto_update: autoUpdate, unload: unload, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async updateOrderStageStatusByManger (orderId: Long, stageNumber: number, status: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateOrderStageStatusByManger,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_order_stage_status_by_manger_args: { order_id: orderId, stage_number: stageNumber, status: status } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async updateStageDeliveryProfilesByManager (profile: Uint8Array, orderId: Long, stageNumber: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateStageDeliveryProfilesByManager,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_stage_delivery_profiles_by_manager_args: { profile: profile, order_id: orderId, stage_number: stageNumber } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async selectOrder (orderId: Long, userId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.selectOrder,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ select_order_args: { order_id: orderId, user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async searchOrders (status: string, limit: number): Promise<object_msg.search_orders_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.searchOrders,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ search_orders_args: { status: status, limit: limit } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_orders_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getStageActionApproximatePrice (deliveryProfileId: Long, stageActionData: Uint8Array): Promise<object_msg.get_stage_action_approximate_price_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getStageActionApproximatePrice,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_stage_action_approximate_price_args: { delivery_profile_id: deliveryProfileId, stage_action_data: stageActionData } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_stage_action_approximate_price_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getActionApproximatePricesForManager (latitude: number, longitude: number, stageActionData: Uint8Array): Promise<object_msg.get_action_approximate_prices_for_manager_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getActionApproximatePricesForManager,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_action_approximate_prices_for_manager_args: { latitude: latitude, longitude: longitude, stage_action_data: stageActionData } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_action_approximate_prices_for_manager_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getValidationCode (phoneNumber: string): Promise<number> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getValidationCode,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_validation_code_args: { phone_number: phoneNumber } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.i!;
  }

  // Allowed roles: Admin, Manager
  async getOrCreateUser (phoneNumber: string): Promise<Long> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getOrCreateUser,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_or_create_user_args: { phone_number: phoneNumber } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.u!;
  }

  // Allowed roles: Admin, Manager
  async addNewOwnerToStagePlace (phoneNumber: string, placeId: Long, placeHaveAdmin: boolean, placeOrgId: Long, orgHaveAdming: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.addNewOwnerToStagePlace,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ add_new_owner_to_stage_place_args: { phone_number: phoneNumber, place_id: placeId, place_have_admin: placeHaveAdmin, place_org_id: placeOrgId, org_have_adming: orgHaveAdming } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async getLastOrganizationUsedForPayment (userId: Long): Promise<Long> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getLastOrganizationUsedForPayment,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_last_organization_used_for_payment_args: { user_id: userId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.u!;
  }

  // Allowed roles: Admin, Manager
  async getUploadUrl (dullArg: boolean): Promise<object_msg.get_upload_url_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getUploadUrl,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_upload_url_args: { dull_arg: dullArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_upload_url_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, Manager
  async getDownloadUrl (fileId: Long): Promise<string> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getDownloadUrl,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_download_url_args: { file_id: fileId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.s!;
  }

  // Allowed roles: Admin, Manager
  async requestCommercialOfferForOrder (orderId: Long, stageNumber: number, packedContactInfo: Uint8Array, message: string, orderFileIds: Uint8Array, stageFileIds: Uint8Array): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.requestCommercialOfferForOrder,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ request_commercial_offer_for_order_args: { order_id: orderId, stage_number: stageNumber, packed_contact_info: packedContactInfo, message: message, order_file_ids: orderFileIds, stage_file_ids: stageFileIds } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async setCommercialOfferReplyTime (orderId: Long, stageNumber: number, receiver: string, receiverType: string, placeId: Long, sendTime: Long, replyTime: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.setCommercialOfferReplyTime,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ set_commercial_offer_reply_time_args: { order_id: orderId, stage_number: stageNumber, receiver: receiver, receiver_type: receiverType, place_id: placeId, send_time: sendTime, reply_time: replyTime } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async reloadCommercialOfferReplyOffers (orderId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.reloadCommercialOfferReplyOffers,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ reload_commercial_offer_reply_offers_args: { order_id: orderId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async verifyEntitiesOnSuccessfullOutgoingPayment (srcPlaceId: Long, srcOrgId: Long, orderId: Long, stageNumber: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.verifyEntitiesOnSuccessfullOutgoingPayment,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ verify_entities_on_successfull_outgoing_payment_args: { src_place_id: srcPlaceId, src_org_id: srcOrgId, order_id: orderId, stage_number: stageNumber } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, Manager
  async verifyEntitiesOnSuccessfullIncomingPayment (srcOrgId: Long, orderId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.verifyEntitiesOnSuccessfullIncomingPayment,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ verify_entities_on_successfull_incoming_payment_args: { src_org_id: srcOrgId, order_id: orderId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// Admin
export class AIApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.AIApi_Id

  // Allowed roles: Admin
  async startCrawl (url: string, infoType: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.startCrawl,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ start_crawl_args: { url: url, info_type: infoType } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async startCrawlGlobal (infoType: number, pagesCount: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.startCrawlGlobal,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ start_crawl_global_args: { info_type: infoType, pages_count: pagesCount } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async startCrawlGlobalSequential (infoType: number, pagesCount: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.startCrawlGlobalSequential,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ start_crawl_global_sequential_args: { info_type: infoType, pages_count: pagesCount } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async setNotCrawledGlobal (infoType: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.setNotCrawledGlobal,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ set_not_crawled_global_args: { info_type: infoType } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deleteCrawlGlobal (infoType: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.deleteCrawlGlobal,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ delete_crawl_global_args: { info_type: infoType } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async approveCrawlGlobal (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.approveCrawlGlobal,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deleteCrawl (url: string, infoType: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.deleteCrawl,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ delete_crawl_args: { url: url, info_type: infoType } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async selectWebsite (url: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.selectWebsite,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ select_website_args: { url: url } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async setPostponeWebsite (url: string, postpone: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.setPostponeWebsite,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ set_postpone_website_args: { url: url, postpone: postpone } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async replaceUrlInCaseOfRedirect (srcUrl: string, destUrl: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.replaceUrlInCaseOfRedirect,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ replace_url_in_case_of_redirect_args: { src_url: srcUrl, dest_url: destUrl } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async collectLinksFromWebsite (url: string, recursive: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.collectLinksFromWebsite,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ collect_links_from_website_args: { url: url, recursive: recursive } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async collectLinksFromWebsites (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.collectLinksFromWebsites,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async collectPhonesEmailsFromWebsites (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.collectPhonesEmailsFromWebsites,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async recompressPengingPages (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.recompressPengingPages,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deletePendingPagesByPermanentUselessPaths (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.deletePendingPagesByPermanentUselessPaths,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async addPermanentUselessPath (path: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.addPermanentUselessPath,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ add_permanent_useless_path_args: { path: path } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async filterPendingPagesUsability (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.filterPendingPagesUsability,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async setUselessPathPermanent (path: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.setUselessPathPermanent,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ set_useless_path_permanent_args: { path: path } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deletePendingPagesWithUselessPath (path: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.deletePendingPagesWithUselessPath,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ delete_pending_pages_with_useless_path_args: { path: path } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async discardUselessPath (path: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.discardUselessPath,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ discard_useless_path_args: { path: path } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async deletePendingPage (mainUrl: string, pendingUrl: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.deletePendingPage,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ delete_pending_page_args: { main_url: mainUrl, pending_url: pendingUrl } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async startScrappingWebsitesFromMap (action: string, pagesPerRun: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.startScrappingWebsitesFromMap,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ start_scrapping_websites_from_map_args: { action: action, pages_per_run: pagesPerRun } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async completeScrappingWebsitesFromMap (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.completeScrappingWebsitesFromMap,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async startScrappingCoordinatesFromMap (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.startScrappingCoordinatesFromMap,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async completeScrappingCoordinatesFromMap (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.completeScrappingCoordinatesFromMap,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  openWebsitesInSelenium (websites: string): void {
    const encodedMessage = object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ open_websites_in_selenium_args: { websites: websites } })).finish();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.openWebsitesInSelenium,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
  }

  // Allowed roles: Admin
  async deletePricePath (path: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.deletePricePath,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ delete_price_path_args: { path: path } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async collectPricePathsFromCollectedInfoJson (): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.collectPricePathsFromCollectedInfoJson,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async getPossibleDuplicatedPlaces (): Promise<object_msg.get_possible_duplicated_places_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.getPossibleDuplicatedPlaces,
      object: this.objectId,
      custom_encoded: new Uint8Array()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_possible_duplicated_places_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin
  async removePlaceCoordinatesAndAddressQuality (id: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.removePlaceCoordinatesAndAddressQuality,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ remove_place_coordinates_and_address_quality_args: { id: id } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async setPlaceCoordinates (id: Long, address: string, latitude: number, longitude: number, town: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.setPlaceCoordinates,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ set_place_coordinates_args: { id: id, address: address, latitude: latitude, longitude: longitude, town: town } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async updatePlaceActionsAndLabelsAccordingToPrices (action: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.AIApiRequest.Method.updatePlaceActionsAndLabelsAccordingToPrices,
      object: this.objectId,
      custom_encoded: object_msg.AIApiRequest.encode(new object_msg.AIApiRequest({ update_place_actions_and_labels_according_to_prices_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 600000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// Admin
export class WebsiteApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.WebsiteApi_Id

  // Allowed roles: Admin
  async reloadWebsiteActionInfo (action: string, autoUpdate: boolean, unloadModel: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.reloadWebsiteActionInfo,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ reload_website_action_info_args: { action: action, auto_update: autoUpdate, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async updateWebsite (websiteActions: Uint8Array): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.updateWebsite,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ update_website_args: { website_actions: websiteActions } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async generateMainPage (productionBuild: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.generateMainPage,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ generate_main_page_args: { production_build: productionBuild } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async generateMainContacts (productionBuild: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.generateMainContacts,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ generate_main_contacts_args: { production_build: productionBuild } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async generateCityContacts (productionBuild: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.generateCityContacts,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ generate_city_contacts_args: { production_build: productionBuild } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async generateMainActions (productionBuild: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.generateMainActions,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ generate_main_actions_args: { production_build: productionBuild } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 30000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin
  async generateServicePages (productionBuild: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.WebsiteApiRequest.Method.generateServicePages,
      object: this.objectId,
      custom_encoded: object_msg.WebsiteApiRequest.encode(new object_msg.WebsiteApiRequest({ generate_service_pages_args: { production_build: productionBuild } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

export function createGlobalPropsAndProvideApi (app: App, socket: WebSocketRpc): void {
  //
  const PlacesApi = new PlacesApi_Remote(socket);
  app.config.globalProperties.$PlacesApi = PlacesApi; app.provide('PlacesApi', PlacesApi);
  const OrganizationsApi = new OrganizationsApi_Remote(socket);
  app.config.globalProperties.$OrganizationsApi = OrganizationsApi; app.provide('OrganizationsApi', OrganizationsApi);
  const ServiceApi = new ServiceApi_Remote(socket);
  app.config.globalProperties.$ServiceApi = ServiceApi; app.provide('ServiceApi', ServiceApi);
  const OrdersApi = new OrdersApi_Remote(socket);
  app.config.globalProperties.$OrdersApi = OrdersApi; app.provide('OrdersApi', OrdersApi);
  const AIApi = new AIApi_Remote(socket);
  app.config.globalProperties.$AIApi = AIApi; app.provide('AIApi', AIApi);
  const WebsiteApi = new WebsiteApi_Remote(socket);
  app.config.globalProperties.$WebsiteApi = WebsiteApi; app.provide('WebsiteApi', WebsiteApi);
}

// API models

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class ActionTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.ActionTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class WebsiteActionsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WebsiteActionsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class ActionMaterialsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.ActionMaterialsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class MachineTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.MachineTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class MaterialTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.MaterialTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LabelTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LabelTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class PlacesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlacesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrganizationsRuModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrganizationsRuModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrganizationsRuInfoModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrganizationsRuInfoModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class BanksRuModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.BanksRuModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceActionsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceActionsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class PlacePhonesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlacePhonesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceEmailsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceEmailsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceMachinesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceMachinesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceLabelsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceLabelsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserCuttingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserCuttingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LocalLaserCuttingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LocalLaserCuttingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserEngravingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserEngravingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LocalLaserEngravingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LocalLaserEngravingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Admin
// Row restriction based on role and user name:
// 
export abstract class LogsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LogsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class CitiesRuModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.CitiesRuModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class CityActionsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.CityActionsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrdersModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrdersModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStatusesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStatusesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderCommentsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderCommentsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderCommentFilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderCommentFilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class UserDeliveryProfilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.UserDeliveryProfilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageStatusesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageStatusesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageDeliveryProfilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageDeliveryProfilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageCommentsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageCommentsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageCommentFilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageCommentFilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class UploadedFilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.UploadedFilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class CommercialOfferRequestsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.CommercialOfferRequestsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserCuttingStagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserCuttingStagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserEngravingStagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserEngravingStagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class WebCrawlerWebsitesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WebCrawlerWebsitesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class WebCrawlerPendingPagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WebCrawlerPendingPagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class WebCrawlerUselessPathsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WebCrawlerUselessPathsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class WebCrawlerPricePathsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WebCrawlerPricePathsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class DepartmentsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.DepartmentsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class DepartmentActionsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.DepartmentActionsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserCuttingMaterialThicknessesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserCuttingMaterialThicknessesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}
